import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cibDiscord,
  cilCode,
  cibBitcoin,
  cilCalculator,
  cilList,
  cilLayers,
  cilWarning,
  //footer
  cilCoffee,
  cilHeart,
  cilSquare,
  //commands
  cilSettings,
  cilLan,
  cilStar,
  cilGamepad,
  cilPeople,
  cilPuzzle,

  //header
  cilPlus,
  cilDescription,
  cilTerminal,
  cibPatreon,
  cilBadge,

  //general
  cilMemory,
  cilMoodVeryGood,
  cilMonitor,
  cilSignalCellular4,
  cilMusicNote,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cibDiscord,
    cilCode,
    cibBitcoin,
    cilCalculator,
    cilList,
    cilLayers,
    cilWarning,
    //footer
    cilCoffee,
    cilHeart,
    cilSquare,
    //commands
    cilSettings,
    cilLan,
    cilStar,
    cilGamepad,
    cilPeople,
    cilPuzzle,

    //header
    cilPlus,
    cilDescription,
    cilTerminal,
    cibPatreon,
    cilBadge,

    //general
    cilMemory,
    cilMoodVeryGood,
    cilMonitor,
    cilSignalCellular4,
    cilMusicNote,
  }
);
