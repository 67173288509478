import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

// import axios from "axios";

// //import { redirectUrl } from "./config.json";
// export let authurl = "http://localhost/login/callback";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

class App extends Component {
  // constructor(props) {
  //   super(props);
  //   this.handleClick = this.handleClick.bind(this);
  //   this.state = {
  //     login: false,
  //     username: "Your beautiful nickname",
  //   };
  // }
  // handleClick() {
  //   // if user is logged in
  //   if (this.state.login) {
  //     // show the logout button
  //     axios
  //       .get("/authorize/logout")
  //       .then((res) => {
  //         this.setState({
  //           login: false,
  //           username: "Your beautiful nickname",
  //         });
  //         this.forceUpdate();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     // if user is not logged in
  //     //show the auth href
  //     console.log(authurl);
  //     window.location.href = authurl;
  //   }
  // }

  // // GETTING THE USER DATA
  // componentDidMount() {
  //   // Please check REACT component lifecycle to understand this, whenever component mounts itself, this method will be called

  //   axios
  //     .get("/authorize/getUserData")
  //     .then((res) => {
  //       console.log("res" + res.data.login);
  //       if (res.data.login) {
  //         this.setState({
  //           login: true,
  //           username: res.data.username,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/"
              name="Main"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
